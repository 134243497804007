import React, { useState } from "react";
import MUICookieConsent from "material-ui-cookie-consent";
import AppBar from "@material-ui/core/AppBar";
import { ThemeProvider, rgbToHex } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { Route, NavLink, BrowserRouter, Switch } from "react-router-dom";
import AuthenticatedRoute from "../components/private-route";
import Import from "../import/import";
import Contact from "../contact/Contact";
import { useAuth0 } from "@auth0/auth0-react";
import AccountsTreeView from "../wiki/accounts.js";
import Articles from "../wiki/articles.js";
import OPKTreeView from "../wiki/opk.js";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Paper from "@material-ui/core/Paper";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import HomeIcon from "@material-ui/icons/Home";
import SchoolIcon from "@material-ui/icons/School";
import PollIcon from "@material-ui/icons/Poll";
import SettingsIcon from "@material-ui/icons/Settings";
import { ImCalculator } from "react-icons/im";
import { RiHealthBookFill } from "react-icons/ri";
import Report011 from "../reports/report001.js";
import Report012 from "../reports/report002.js";
import Report021 from "../reports/report021.js";
import Report004 from "../reports/report004.js"; //OPK costs
import Report005 from "../reports/report005.js"; //accounts costs
import Report006 from "../reports/report006.js"; //support unit allocation
import Report007 from "../reports/report007.js"; //support unit allocation
import Report008 from "../reports/report008.js"; //support unit allocation
import Report009 from "../reports/report009.js"; //cost by stages of allocation
import Report010 from "../reports/report010.js"; //commercial unit report
import Report001 from "../reports/report011.js"; //procedures summary report
import Report020 from "../reports/report020.js"; //AOTMIT report
import Report022 from "../reports/report022.js"; //export allocations
import Report023 from "../reports/report023.js"; //other OPK reports
import Report024 from "../reports/report024.js"; //salaries report
import Report025 from "../reports/report025.js"; //AOTMiT FK.OPK report
import Report026 from "../reports/report026.js"; //Procedures report
import Report028 from "../reports/report028.js"; //Cost per day report
import Report029 from "../reports/report029.js"; //OPK Summary (dedicated) report
import Report030 from "../reports/report030.js"; //Result by OPK (dedicated) report
import Report031 from "../reports/report031.js"; //Result summary (dedicated) report
import Report032 from "../reports/report032.js"; //Cost grouping (dedicated) report
import Dashboard from "../reports/dashboard.js"; //Default dashboard
import FirstStep from "../wiki/firstStep.js"; //First step Excel video
import ProceduresFirstStep from "../wiki/proceduresFirstStep.js"; //First step in Procedures video
import Procedureslibrary from "../procedures/proceduresLibrary";
import WhatsNew from "../wiki/whatsNew/whatsNew.js";
import Commercial from "../wiki/commercial.js";
import ReportsIntro from "../wiki/reportsIntro.js";

import RegisteredCodesRep from "../reports/RegisteredCodesRep";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArticleViewer from "../wiki/articles/ArticleViewer";

import ArticleEditor from "../wiki/articles/ArticleEditor";
import Startup001 from "../wiki/startup/startup001.js";
import UserManual001 from '../wiki/usermanual/usermanual001';
import RegisterStart from "../users/registerStart.js";
import RegisterEvent from "../users/registerEvent.js";
import RegisterEvent2 from "../users/registerEvent2.js";
import Register from "../users/register.js";

import Users from "../users/users.js";

import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import ToolTip from "@material-ui/core/Tooltip";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Procedures from "../procedures/procedures";
import ProceduresData from "../procedures_data/importdictionaries";
import ProceduresManager from "../procedures_manager/importmanager";
import { ConfirmProvider } from "material-ui-confirm";

import { Link, animateScroll as scroll } from "react-scroll";
import { withRouter } from 'react-router-dom';

import Home from "../home/home/Index.js";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
} from "react-pro-sidebar";

import "react-pro-sidebar/dist/css/styles.css";
import "./custom.scss";
import "./Styles.css";

import { createMuiTheme } from "@material-ui/core/styles";
import { plPL } from "@material-ui/core/locale";
import { apiurl } from './prefs.js';

const theme = createMuiTheme(
  {
    spacing: 0,
    palette: {
      primary: {
        //main: "#0b6471",
        main: "#0b6471",
      },
      secondary: {
        main: "#FE8E3C",
      },
    },
  },
  plPL
);

const NoMatchPage = () => {
  return <h3>Nie ma strony o takim adresie (404)</h3>;
};

const sidemenuWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    top: "0px",
    flexGrow: 1,
    backgroundColor: "rgba(250,250,250,0.8)",
    color: "#182634",
    color: "black",
    padding: "0px",
    paddingTop: "5px",
    borderRadius: "5px",
    minHeight: "48px",
    height: "56px",
    fontSize: "20px",
    marginBottom: "15px",
    width: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "#303030",
  },
  content: {
    flexGrow: 1,
    padding: "10px",

    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.short,
    }),
    marginLeft: "5px",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -sidemenuWidth,
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: "bold",
    flexBasis: "90%",
    flexShrink: 0,
  },
}));

var GlobalToken = "";

function useAPI() {
  const [accessToken, setAccessToken] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  async function getAccessToken() {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
      //console.log("getting token");
      GlobalToken = token;
    } catch (e) {
      //console.log(e);
    }
  }
  return {
    accessToken,
    getAccessToken,
  };
}

function App() {
  const inputEl = React.useRef(null);

  const classes = useStyles();
  const { user } = useAuth0();
  const {
    isLoading,
    logout,
    isAuthenticated,
    loginWithPopup,
    loginWithRedirect,
  } = useAuth0();
  const matchesWidth = useMediaQuery("(min-width:600px)");
  //getAccessToken();
  const [cookiePolicyOpen, setCookiePolicyOpen] = React.useState(false);
  const [articleList, setArticleList] = React.useState(null);

  const handleClickOpen = () => {
    setCookiePolicyOpen(true);
  };

  const HomeButton = withRouter(({ history, location }) => (
    <IconButton
      style={{ marginRight: "0px" }}
      edge="start"
      className={classes.menuButton}
      color="inherit"
      aria-label="menu"
      onClick={() => { location.pathname !== '/' ? history.push('/') : scroll.scrollToTop() }}
    >
      <HomeIcon style={{ color: '#0b6471' }} />
    </IconButton>
  ))

  const handleClose = () => {
    setCookiePolicyOpen(false);
  };

  React.useEffect(() => {
    fetch(`${apiurl}/articles`)
      .then((res) => res.json())
      .then((data) => {
        setArticleList(data);
      });
  }, []);

  const singleLogin = () => {
    loginWithRedirect();
  };

  const isAuthorized = (requiredRights) => {
    let checkResult = false;

    checkResult =
      isAuthenticated
      && user["https://coplus.pl/md"]
      && user["https://coplus.pl/md"].permissions
      && user["https://coplus.pl/md"].permissions.role
      && requiredRights.some(
        role => user["https://coplus.pl/md"].permissions.role.includes(role))

    return checkResult;
  }

  class MainMenu extends React.Component {
    state = {
      mainmenuopen: true,
    };
    changeMainMenu = () => {
      this.setState({
        mainmenuopen: !this.state.mainmenuopen,
      });
    };
    render() {
      return (
        <ProSidebar collapsed={this.state.mainmenuopen}>
          <Menu iconShape="none" popperArrow={true}>
            <MenuItem icon={<HomeIcon />}>
              Program controllingowy
              <NavLink exact to="/"></NavLink>
            </MenuItem>
            <SubMenu title="Raporty" icon={<PollIcon />}>

              <MenuItem
                style={
                  isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling',])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }
              >
                Konta 4<NavLink to="/report005"></NavLink>
              </MenuItem>
              <MenuItem
                style={
                  isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }
              >
                Konta 5<NavLink to="/report004"></NavLink>
              </MenuItem>
              <MenuItem style={
                isAuthorized(['Admin', 'LocalAdmin', 'Controlling', 'Director'])
                  ? {}
                  : { color: "gray", pointerEvents: "none" }
              }>
                Etapy rozliczeń<NavLink to="/report009"></NavLink>
              </MenuItem>
              <MenuItem
                style={
                  isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling', 'Manager'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }
              >
                OPK Pomocnicze<NavLink to="/report006"></NavLink>
              </MenuItem>
              <MenuItem
                style={
                  isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling', 'Manager'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }
              >
                OPK Proceduralne<NavLink to="/report007"></NavLink>
              </MenuItem>
              <MenuItem style={
                isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling', 'Manager'])
                  ? {}
                  : { color: "gray", pointerEvents: "none" }
              }>
                Oddziały<NavLink to="/report012"></NavLink>
              </MenuItem>
              <MenuItem style={
                isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling', 'Manager'])
                  ? {}
                  : { color: "gray", pointerEvents: "none" }
              }>
                Poradnie<NavLink to="/report021"></NavLink>
              </MenuItem>
              <MenuItem style={
                isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling', 'Manager'])
                  ? {}
                  : { color: "gray", pointerEvents: "none" }}>
                Komercja<NavLink to="/report010"></NavLink>
              </MenuItem>
              <MenuItem style={
                isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling', 'Manager'])
                  ? {}
                  : { color: "gray", pointerEvents: "none" }}>
                Pozostałe OPK<NavLink to="/report023"></NavLink>
              </MenuItem>
              <MenuItem style={
                isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling'])
                  ? {}
                  : { color: "gray", pointerEvents: "none" }}>
                Procedury<NavLink to="/report026"></NavLink>
              </MenuItem>
              <MenuItem style={
                isAuthorized(['Admin', 'LocalAdmin', 'Director', 'Controlling'])
                  ? {}
                  : { color: "gray", pointerEvents: "none" }}>
                <sup style={{ color: "#FE8E3C", fontWeight: "bold" }}>N</sup> Koszt Osobodnia<NavLink to="/report028"></NavLink>
              </MenuItem>
              <MenuItem
                style={
                  isAuthorized(['Admin', 'LocalAdmin', 'Director', 'Controlling'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }
              >
                Ranking wynagrodzeń
                <NavLink to="/report024"></NavLink>
              </MenuItem>
              <MenuItem
                style={
                  isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }
              >
                Dyrektor<NavLink to="/report011"></NavLink>
              </MenuItem>
              <SubMenu title='AOTMiT'>
                <MenuItem
                  style={
                    isAuthorized(['Admin', 'LocalAdmin', 'Director', 'Controlling'])
                      ? {}
                      : { color: "gray", pointerEvents: "none" }
                  }
                >
                  FK.OPK<NavLink to="/report025"></NavLink>
                </MenuItem>
                <MenuItem
                  style={
                    isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling'])
                      ? {}
                      : { color: "gray", pointerEvents: "none" }
                  }
                >
                  Załącznik nr 4<NavLink to="/report020"></NavLink>
                </MenuItem>
              </SubMenu>
              <SubMenu title={<div><sup style={{ color: "#FE8E3C", fontWeight: "bold" }}>N</sup> Dedykowane</div>}>
                <MenuItem
                  style={
                    isAuthorized(['Admin', 'LocalAdmin', 'Director', 'Controlling'])
                      ? {}
                      : { color: "gray", pointerEvents: "none" }
                  }
                >
                  Podsumowanie OPK<NavLink to="/report029"></NavLink>
                </MenuItem>
                <MenuItem
                  style={
                    isAuthorized(['Admin', 'LocalAdmin', 'Director', 'Controlling'])
                      ? {}
                      : { color: "gray", pointerEvents: "none" }
                  }
                >
                  Rozliczenie wyniku<NavLink to="/report030"></NavLink>
                </MenuItem>
                <MenuItem
                  style={
                    isAuthorized(['Admin', 'LocalAdmin', 'Director', 'Controlling'])
                      ? {}
                      : { color: "gray", pointerEvents: "none" }
                  }
                >
                  Podsumowanie wyniku<NavLink to="/report031"></NavLink>
                </MenuItem>
                <MenuItem
                  style={
                    isAuthorized(['Admin', 'LocalAdmin', 'Director', 'Controlling'])
                      ? {}
                      : { color: "gray", pointerEvents: "none" }
                  }
                >
                  Zestawienie kosztów<NavLink to="/report032"></NavLink>
                </MenuItem>
              </SubMenu>
              <MenuItem
                style={
                  isAuthorized(['Admin'])
                    ? {}
                    : { display: "none", pointerEvents: "none" }
                }
              >
                JGP<NavLink to="/report008"></NavLink>
              </MenuItem>
            </SubMenu>
            <SubMenu title="Baza wiedzy" icon={<SchoolIcon />}>
              <MenuItem>
                <sup style={{ color: "#FE8E3C", fontWeight: "bold", marginRight: '3px' }}>N</sup>
                Aktualności<NavLink to="/whatsnew"></NavLink>
              </MenuItem>
              <MenuItem>
                Publikacje<NavLink to="/articles"></NavLink>
              </MenuItem>
              <SubMenu
                style={
                  !isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Controlling', 'Startup'])
                    ? { color: "gray", pointerEvents: "none" }
                    : {}
                }
                title="Słowniki"
                icon={<HomeIcon />}
              >
                <MenuItem>
                  Zespół kont 4<NavLink to="/accounts"></NavLink>
                </MenuItem>
                <MenuItem>
                  Zespół kont 5<NavLink to="/opk"></NavLink>
                </MenuItem>
                <MenuItem>
                  <sup style={{ color: "#FE8E3C", fontWeight: "bold", marginRight: '3px' }}>N</sup>
                  Opisy procedur<NavLink to="/procedureslibrary"></NavLink>
                </MenuItem>
                <MenuItem style={{ color: "gray", pointerEvents: "none", display: 'none' }}>
                  Słownik procedur ICD-9
                </MenuItem>
              </SubMenu>
              <SubMenu
                title="Instrukcja użytkownika"
                style={
                  isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Controlling', 'Director', 'Manager'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }
              >
                <MenuItem style={
                  isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Controlling'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }>
                  Import danych - SRK<NavLink to="/usermanual/instrukcja_importu_srk"></NavLink>
                </MenuItem>

                <MenuItem style={
                  isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Controlling'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }>
                  Dodawanie użytkowników<NavLink to="/usermanual/uzytkownicy"></NavLink>
                </MenuItem>

                <MenuItem style={
                  isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Controlling'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }>
                  Opis procedur<NavLink to="/usermanual/instrukcja_opisu_procedur"></NavLink>
                </MenuItem>

                <SubMenu title=' Opis raportów' style={
                  isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling', 'Director', 'Manager'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }>

                  {articleList &&
                    articleList.filter(art => art.CAT === 'Podręcznik użytkownika').map((article) => (

                      <MenuItem key={article.ID} style={
                        isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling', 'Manager'])
                          ? {}
                          : { color: "gray", pointerEvents: "none" }
                      }>
                        {article.TITLE.replace('Raport: ', '')} <NavLink to={article.REF2}></NavLink>
                      </MenuItem>
                    ))}
                </SubMenu>

              </SubMenu>
              <SubMenu
                title="Szkolenia"
                style={
                  isAuthorized(['Admin'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }
              >
              </SubMenu>
            </SubMenu>
            <SubMenu title="Rachunek kosztów"
              icon={<ImCalculator style={{ width: "18px", height: "18px" }} />}
            >
              <MenuItem
                style={
                  isAuthorized(['Admin', 'LocalAdmin'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }
              >
                Import danych - SRK
                <NavLink to="/import" />
              </MenuItem>
              <MenuItem
                style={
                  isAuthorized(['Admin', 'LocalAdmin', 'Controlling'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }
              >
                Eksport rozliczeń
                <NavLink to="/exportallocations" />
              </MenuItem>
            </SubMenu>
            <SubMenu title="Wycena procedur"
              icon={
                <RiHealthBookFill style={{ width: "22px", height: "22px" }} />
              }
            >
              <MenuItem
                style={
                  isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }
              >
                Import danych - procedury<NavLink to="/procedury/import"></NavLink>
              </MenuItem>

              <MenuItem
                style={
                  isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'ProcedureEditor'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }
              >
                Opis procedur<NavLink to="/procedury"></NavLink>
              </MenuItem>

              <MenuItem
                style={
                  isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }
              >
                Zestawienie procedur<NavLink to="/report001"></NavLink>
              </MenuItem>

            </SubMenu>
            <SubMenu title="Administracja" icon={<SettingsIcon />}>
              <MenuItem
                style={
                  isAuthorized(['Admin', 'LocalAdmin'])
                    ? {}
                    : { color: "gray", pointerEvents: "none" }
                }
              >
                Użytkownicy<NavLink to="/users"></NavLink>
              </MenuItem>
              <MenuItem style={{ color: "gray", pointerEvents: "none", display: 'none' }}>
                Baza danych
              </MenuItem>
              <MenuItem style={
                isAuthorized(['Admin', 'LocalAdmin', 'ProcedureAdmin', 'Director', 'Controlling', 'Manager'])
                  ? {}
                  : { color: "gray", pointerEvents: "none" }
              }>
                Kontakt<NavLink to='/contact' />
              </MenuItem>
              <MenuItem style={
                isAuthorized(['Admin'])
                  ? {}
                  : { color: "gray", pointerEvents: "none", display: 'none' }
              }>
                Rejestr podmiotów<NavLink to='/office/registeredcodes' />
              </MenuItem>
            </SubMenu>
          </Menu>
        </ProSidebar>
      );
    }
  }

  return (
    articleList && (
      <div style={{ backgroundColor: "rgb(210,210,210)" }}>

        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            <AppBar position="static" className={classes.root} style={{ zIndex: 850 }}>
              <Toolbar style={{ minHeight: "10px" }}>
                {matchesWidth && (
                  user ? <IconButton
                    style={{ marginRight: "0px" }}
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={() => {
                      inputEl.current.changeMainMenu()
                    }}
                  >

                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" version="1.1">
                      <rect x="0" y="1" width="20" height="2" fill="#005BBB" />
                      <rect x="0" y="5" width="20" height="2" fill="#005BBB" />
                      <rect x="0" y="9" width="20" height="2" fill="#FFD500" />
                      <rect x="0" y="13" width="20" height="2" fill="#FFD500" />
                    </svg>
                  </IconButton>
                    :
                    <HomeButton />
                )}
                <img
                  src="/logo192.svg"
                  alt="COplus"
                  style={{
                    width: "56px",
                    height: "56px",
                    marginRight: "10px",
                    padding: "0px",
                  }}
                ></img>

                <Typography
                  variant="h6"
                  className={classes.title}
                  edge="start"
                >
                  Program controllingowy
                </Typography>

                {matchesWidth && (
                  <div>
                    {user && <IconButton
                      edge="end"
                      aria-label="show new mails"
                      color="inherit"
                    >
                      <Badge badgeContent={0} color="primary">
                        <MailIcon
                          style={
                            isAuthenticated
                              ? { color: "black" }
                              : { color: "gray" }
                          }
                        />
                      </Badge>
                    </IconButton>}
                    {user && <IconButton
                      edge="end"
                      aria-label="show new notifications"
                      color="inherit"
                    >
                      <Badge badgeContent={0} color="secondary">
                        <NotificationsIcon
                          style={
                            isAuthenticated
                              ? { color: "black" }
                              : { color: "gray" }
                          }
                        />
                      </Badge>
                    </IconButton>}
                    <ToolTip
                      title={
                        user
                          ? `Zalogowany jako: ${user.name}`
                          : "Nie zalogowany"
                      }
                      placement="bottom-start"
                    >
                      <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() =>
                          isAuthenticated ? logout() : singleLogin()
                        }
                        color="inherit"
                      >
                        <AccountCircle
                          style={
                            isAuthenticated
                              ? { color: "black" }
                              : { color: "gray" }
                          }
                        />
                      </IconButton>
                    </ToolTip>
                  </div>
                )}
              </Toolbar>
            </AppBar>
            <div style={{ display: "flex", postition: "relative" }}>
              <div
                style={{
                  display: matchesWidth ? "" : "none",
                  height: "100%",
                  position: "sticky",
                  top: "70px",
                  zIndex: 900,
                }}
              >
                {user && <MainMenu ref={inputEl} />}
              </div>
              <Paper
                className={classes.content}
                style={{
                  border: "0px solid",
                  padding: "5px",
                  marginLeft: (matchesWidth && user) ? "15px" : "0px",

                  backgroundColor: "rgb(240,240,240)",
                  minHeight: 'calc(100vh - 80px)',
                  overflow: 'auto'
                }}
              >
                <Switch>
                  {
                    isAuthenticated ?
                      isAuthorized(['Admin', 'LocalAdmin', 'Director', 'Controlling']) ?
                        <AuthenticatedRoute exact path="/" component={Dashboard} />
                        :
                        isAuthorized(['ProcedureAdmin', 'ProcedureEditor']) ?
                          <AuthenticatedRoute exact path="/" component={Procedures} />
                          :
                          <Route exact path="/" component={Home} />
                      :
                      <Route exact path="/" component={Home} />


                  }
                  <AuthenticatedRoute path="/import" component={Import} />
                  <AuthenticatedRoute path="/contact" component={Contact} />
                  <AuthenticatedRoute
                    exact
                    path="/procedury"
                    component={Procedures}
                  />
                  <AuthenticatedRoute
                    path="/accounts"
                    component={AccountsTreeView}
                  />
                  <AuthenticatedRoute path="/opk" component={OPKTreeView} />
                  <Route exact path="/articles" component={Articles} />
                  <AuthenticatedRoute
                    path="/report011"
                    component={Report011}
                  />
                  <AuthenticatedRoute
                    path="/report009"
                    component={Report009}
                  />
                  <AuthenticatedRoute
                    path="/report010"
                    component={Report010}
                  />
                  <AuthenticatedRoute
                    path="/report012"
                    component={Report012}
                  />
                  <AuthenticatedRoute
                    path="/report021"
                    component={Report021}
                  />

                  <AuthenticatedRoute
                    path="/report004"
                    component={Report004}
                  />
                  <AuthenticatedRoute
                    path="/report005"
                    component={Report005}
                  />
                  <AuthenticatedRoute
                    path="/report006"
                    component={Report006}
                  />
                  <AuthenticatedRoute
                    path="/report007"
                    component={Report007}
                  />
                  <AuthenticatedRoute
                    path="/report008"
                    component={Report008}
                  />
                  <AuthenticatedRoute
                    path="/report001"
                    component={Report001}
                  />
                  <AuthenticatedRoute
                    path="/report020"
                    component={Report020}
                  />
                  <AuthenticatedRoute
                    path="/exportallocations"
                    component={Report022}
                  />
                  <AuthenticatedRoute
                    path="/report023"
                    component={Report023}
                  />
                  <AuthenticatedRoute
                    path="/report024"
                    component={Report024}
                  />
                  <AuthenticatedRoute
                    path="/report025"
                    component={Report025}
                  />
                  <AuthenticatedRoute
                    path="/report026"
                    component={Report026}
                  />
                  <AuthenticatedRoute
                    path="/report028"
                    component={Report028}
                  />
                  <AuthenticatedRoute
                    path="/report029"
                    component={Report029}
                  />
                  <AuthenticatedRoute
                    path="/report030"
                    component={Report030}
                  />
                  <AuthenticatedRoute
                    path="/report031"
                    component={Report031}
                  />
                  <AuthenticatedRoute
                    path="/report032"
                    component={Report032}
                  />
                  <AuthenticatedRoute
                    path="/whatsnew"
                    component={WhatsNew}
                  />
                  <AuthenticatedRoute
                    path="/startup"
                    component={Startup001}
                  />
                  <AuthenticatedRoute
                    path="/usermanual001"
                    component={UserManual001}
                  />
                  <AuthenticatedRoute
                    exact
                    path="/procedury/import"
                    component={ProceduresData}
                  />
                  <AuthenticatedRoute
                    exact
                    path="/procedury/manager"
                    component={ProceduresManager}
                  />
                  <AuthenticatedRoute
                    exact
                    path="/procedureslibrary"
                    component={Procedureslibrary}
                  />
                  <AuthenticatedRoute
                    exact
                    path="/articleeditor"
                    component={ArticleEditor}
                  />
                  <Route path="/registerstart" component={RegisterStart} />

                  <Route
                    path="/pierwsze_zestawienia"
                    component={FirstStep}
                  />

                  <Route
                    path="/procedury_wstep"
                    component={ProceduresFirstStep}
                  />

                  <Route
                    path="/reklama"
                    component={Commercial}
                  />

                  <Route
                    path="/raporty"
                    component={ReportsIntro}
                  />

                  <Route exact path="/webinarium/pfsz" component={RegisterEvent2} />
                  <Route
                    exact
                    path="/register/:id"
                    component={Register}
                  />
                  <AuthenticatedRoute path="/users" component={Users} />
                  <AuthenticatedRoute path="/office/registeredcodes" component={RegisteredCodesRep} />

                  {articleList &&
                    articleList.filter(art => art.CAT !== 'Podręcznik użytkownika' && art.CAT !== 'Instrukcja użytkownika').map((article) => (
                      <Route
                        key={article.ID}
                        exact
                        path={(article.CAT === 'Regulamin') ? `${article.REF2}` : `/articles${article.REF2}`}
                        component={() => (
                          <ArticleViewer
                            id={article.ID}
                            category={article.CAT}
                            title={article.TITLE}
                          />
                        )}
                      />
                    ))}
                  {articleList &&
                    articleList.filter(art => art.CAT === 'Podręcznik użytkownika').map((article) => (
                      <AuthenticatedRoute
                        key={article.ID}
                        exact
                        path={`${article.REF2}`}
                        component={() => (
                          <ArticleViewer
                            id={article.ID}
                            category={article.CAT}
                            title={article.TITLE}
                          />
                        )}
                      />
                    ))}
                  {articleList &&
                    articleList.filter(art => art.CAT === 'Instrukcja użytkownika').map((article) => (
                      <AuthenticatedRoute
                        key={article.ID}
                        exact
                        path={`${article.REF2}`}
                        component={() => (
                          <ArticleViewer
                            id={article.ID}
                            category={article.CAT}
                            title={article.TITLE}
                          />
                        )}
                      />
                    ))}
                  <Route
                    exact
                    path='/articles/Rachunek kosztów'
                    component={() => <Articles target='Rachunek kosztów' />}
                  />
                  <Route
                    exact
                    path='/articles/Rozporządzenie'
                    component={() => <Articles target='Rozporządzenie' />}
                  />
                  <Route
                    exact
                    path='/articles/Wycena procedur medycznych'
                    component={() => <Articles target='Wycena procedur medycznych' />}
                  />
                  <Route
                    render={({ staticContext }) => {
                      if (staticContext) {
                        staticContext.statusCode = 404;
                      }
                      return <NoMatchPage />;
                    }}
                  />
                </Switch>
              </Paper>
            </div>
            <MUICookieConsent
              cookieName="coplusCookieConsent"
              acceptButtonLabel="Akceptuję"
              componentType="Snackbar" // or Dialog
              message={
                <p>
                  W celu świadczenia usług na najwyższym poziomie (w tym w
                  celach statystycznych i funkcjonalnych) stosujemy pliki
                  cookies. Dalsze korzystanie z serwisu oznacza akceptację. W
                  każdym momencie można dokonać zmiany ustawień Państwa
                  przeglądarki.
                  <a
                    style={{
                      cursor: "pointer",
                      color: "orange",
                      paddingLeft: "4px",
                    }}
                    onClick={handleClickOpen}
                  >
                    Zobacz politykę cookies.
                  </a>
                </p>
              }
            ></MUICookieConsent>
            <Dialog
              open={cookiePolicyOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              style={{ zIndex: 800 }}
            >
              <DialogTitle id="alert-dialog-title">
                {"Polityka dotycząca cookies"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <p>
                    W celu świadczenia usług na najwyższym poziomie (w tym w
                    celach statystycznych i funkcjonalnych) stosujemy pliki
                    cookies. Dalsze korzystanie z serwisu oznacza akceptację.
                    W każdym momencie można dokonać zmiany ustawień Państwa
                    przeglądarki. Dodatkowo, korzystanie z naszej witryny
                    oznacza akceptację przez Państwa klauzuli przetwarzania
                    danych osobowych udostępnionych drogą elektroniczną.
                  </p>
                  <p>
                    Pliki cookies (tzw. ciasteczka) to niewielkie pliki
                    tekstowe wysyłane przez odwiedzany serwis internetowy do
                    urządzenia internauty (komputer, smartfon itp.).
                  </p>
                  <p>
                    Pliki cookies zawierają najczęściej adres strony
                    internetowej, czas przechowywania na urządzeniu
                    użytkownika oraz własny unikatowy identyfikator. Nie
                    zawierają one żadnych danych osobowych.
                  </p>
                  <p>
                    Stosujemy pliki cookies sesyjne (tymczasowe) oraz stałe.
                    Cookies sesyjne przechowywane są na urządzeniu użytkownika
                    do wylogowania ze strony internetowej lub wyłączenia
                    przeglądarki internetowej. Cookies stałe przechowywane są
                    przez zdefiniowany czas, który określany jest parametr
                    zawarty w pliku cookies - użytkownik dysponuje możliwością
                    ich ręcznego usunięcia.
                  </p>
                  <p>
                    W większości przeglądarek internetowych można usunąć pliki
                    cookies z twardego dysku komputera (z poziomu ustawień
                    przeglądarki), zablokować wszystkie przysyłane pliki typu
                    cookies lub ustawić ostrzeżenie przed zapisaniem ich na
                    dysku.
                  </p>
                  <p>
                    Należy mieć na uwadze, że zmiany ustawień tych plików
                    polegające na ograniczeniu ich stosowania mogą wpłynąć na
                    niektóre funkcjonalności dostępne na stronach
                    internetowych je stosujących np. uniemożliwiając
                    zalogowanie się. Brak zmiany tych ustawień oznacza
                    akceptację dla stosowania plików typu cookies.
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Rozumiem
                </Button>
              </DialogActions>
            </Dialog>
          </ConfirmProvider>
        </ThemeProvider>

      </div>
    )
  );
}

export default withRouter(App);
export { GlobalToken };
