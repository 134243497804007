import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import PollIcon from "@material-ui/icons/Poll";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Popover } from "@material-ui/core";

import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { LineChart, Line, Tooltip, XAxis } from "recharts";
import { ReactComponent as XlsxIcon } from './xlsx.svg';
import MUITooltip from "@material-ui/core/Tooltip";
import LibraryIcon from '@material-ui/icons/LocalLibraryOutlined';
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { apiurl } from '../app/prefs.js';

//Cost Per Day Report

function format(n) {
  var parts = Number(n) || 0;
  parts = parts.toString().split(".");
  const numberPart = parts[0];
  const decimalPart =
    parts[1] && Math.round(parts[1] / Math.pow(10, parts[1].length - 2));
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return (
    numberPart.replace(thousands, "\xa0") +
    (decimalPart ? "," + decimalPart : "")
  );
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-procedures">
        <p className="label-procedures">{`${label}:`}</p>
        <p className="value-procedures">{`${format(payload[0].value)}`}</p>
      </div>
    );
  }
  return null;
};

const InLineChart = (props) => {
  const CustomizedDot = (props) => {
    const { cx, cy, value } = props;

    return (
      <svg
        x={cx - 3}
        y={cy}
        width={6}
        height={2}
        fill={value === 0 ? "#b1b1b1" : value > 0 ? "#7a7a7a" : "#FE8E3C"}
        viewBox="0 0 6 2"
      >
        <rect width="6" height="2" />
      </svg>
    );
  };
  const convertMonths = (input) => {
    const roman = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12"
    ];
    return Array.isArray(input)
      ? input.map((m, i) => {
        return { m: roman[i], v: m || 0 };
      })
      : [];
  };

  const data = convertMonths(props.data);

  return (
    <LineChart
      style={{ marginLeft: "15px" }}
      syncId={props.id}
      width={100}
      height={25}
      data={data}
    >
      <XAxis hide dataKey="m" />
      <Tooltip
        wrapperStyle={{ backgroundColor: "white", zIndex: 1 }}
        cursor={{ stroke: "orange", fill: "transparent", strokeWidth: "1px" }}
        content={<CustomTooltip />}
        allowEscapeViewBox={{ x: true, y: true }}
      />
      <Line
        type="linear"
        isAnimationActive={false}
        dot={<CustomizedDot />}
        dataKey="v"
        stroke="gray"
        strokeWidth={0}
      ></Line>
    </LineChart>
  );
};

function ProceduresSummary(props) {
  const { row } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  React.useEffect(
    () => {
      setPage(0)
    }, [row]
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper
      style={{
        minWidth: "410px",
        width: "100%",
        padding: "5px",
        marginBottom: "10px",
        marginRight: "0px",
      }}
    >
      <Typography
        style={{
          color: "#0b6471",
          fontSize: "16px",
          fontWeight: "100",
          borderBottom: "0px solid",
          paddingLeft: "10px",
          paddingTop: "5px",
        }}
      >
        ANALIZA KOSZTU OSOBODNIA
      </Typography>

      <TableContainer>
        <Table size="small" style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Kod
              </TableCell>
              <TableCell
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Nazwa
              </TableCell>
              <TableCell
                align="right"
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Ilość osobodni
              </TableCell>

              <TableCell
                align="right"
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Koszt
              </TableCell>

              <TableCell
                align="right"
                style={{
                  color: "#0b6471",
                  fontSize: "16px",
                  fontWeight: "100",
                }}
              >
                Koszt osobodnia
              </TableCell>


            </TableRow>
          </TableHead>
          <TableBody>
            {row &&
              row
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((child) => (
                  <TableRow key={child.id} hover="true">
                    <TableCell>
                      {child.id}
                    </TableCell>
                    <TableCell>
                      {child.OPK_name}
                    </TableCell>
                    <TableCell align="right">
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <InLineChart id={child.id} data={child.monthly.map(m => m.hospital_days)} />
                        {child.hospital_days.toLocaleString("pl-PL", {
                          maximumFractionDigits: 0,
                        })}
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <InLineChart id={child.id} data={child.monthly.map(m => m.cost)} />
                        {child.cost.toLocaleString("pl-PL", {
                          maximumFractionDigits: 2, minimumFractionDigits: 2,
                        })}
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      <div style={{ display: 'flex', justifyContent: 'space-between', color: child.costPerDay > 0 ? 'black' : '#FE8E3C' }}>
                        <InLineChart id={child.id} data={child.monthly.map(m => m.costPerDay)} />
                        {child.costPerDay.toLocaleString("pl-PL", {
                          maximumFractionDigits: 2, minimumFractionDigits: 2,
                        })}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={row && row.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );
}

export default function Report028() {
  const [reportDataLoaded, setReportDataLoaded] = React.useState(false);
  const [reportData, setReportData] = React.useState(null);
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);
  const [dateList, setDateList] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const sortByOptions = [
    { id: 'id', name: 'Kod', direction: 'Malejąco' },
    { id: 'OPK_name', name: 'Nazwa', direction: 'Malejąco' },
    { id: 'hospital_days', name: 'Ilość osobodni', direction: 'Malejąco' },
    { id: 'cost', name: 'Koszt', direction: 'Malejąco' },
    { id: 'costPerDay', name: 'Koszt osobodnia', direction: 'Malejąco' },
    { id: 'id', name: 'Kod', direction: 'Rosnąco' },
    { id: 'OPK_name', name: 'Nazwa', direction: 'Rosnąco' },
    { id: 'hospital_days', name: 'Ilość osobodni', direction: 'Rosnąco' },
    { id: 'cost', name: 'Koszt', direction: 'Rosnąco' },
    { id: 'costPerDay', name: 'Koszt osobodnia', direction: 'Rosnąco' },
  ];
  const [sortBy, setSortBy] = React.useState(sortByOptions[4]);
  const [reportInfoOpen, setReportInfoOpen] = React.useState(false);

  const loadData = (year) => {
    setReportDataLoaded(false);
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(`${apiurl}/costperday/${year}/json`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setReportData(data);
          setReportDataLoaded(true);
        });
    });
  }

  function SortByBox(props) {
    return (
      <Autocomplete
        id="combo-box1"
        //disabled={!props.data}
        options={sortByOptions}
        value={sortBy}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.name}
        groupBy={option => option.direction}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.id === value.id && option.direction === value.direction;
        }}
        style={{ width: "220px", marginBottom: "10px", marginLeft: '10px', marginRight: '10px' }}
        renderInput={(params) => <TextField {...params} label="Sortowanie według" />}
        onChange={(event, value) => {
          if (value) {
            setSortBy(value);
            //if (activeYard) loadData(activeYard, value.year);
          }
        }}
      />
    );
  }

  function DateBox(props) {
    return (
      <Autocomplete
        id="combo-box"
        disabled={!props.data}
        options={props.data}
        value={startDate}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.year && option.year.toLocaleString()}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.year.toLocaleString() === value;
        }}
        style={{ width: "80px", marginBottom: "10px", marginLeft: '10px', marginRight: '10px' }}
        renderInput={(params) => <TextField {...params} label="Rok" />}
        onChange={(event, value) => {
          if (value) {
            setStartDate(value);
            loadData(value.year);
          }
        }}
      />
    );
  }

  const loadFilters = () => {
    setFiltersLoaded(false);
    const apiUrl = `${apiurl}/yearslist`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          setDateList(fetchedData.datelist);
          if (fetchedData.datelist && fetchedData.datelist.length !== 0) {
            setStartDate(fetchedData.datelist[fetchedData.datelist.length - 1]);
            //loadData(fetchedData.datelist[fetchedData.datelist.length - 1].year);
            setFiltersLoaded(true);
            loadData(fetchedData.datelist[fetchedData.datelist.length - 1].year);
          }
        });
    });
  }

  React.useEffect(() => {
    let mounted = true;
    //console.log("Main mounted");
    loadFilters();
    return function cleanup() {
      //console.log("Main unmounted");
      mounted = false;
    };
  }, []);

  const downloadFile = (downloadURL) => {

    getAccessTokenSilently().then((token) => {
      document.body.style.cursor = 'wait';
      const apiUrl = downloadURL
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Raport kosztów osobodni.xlsx`,
          );
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          document.body.style.cursor = 'auto';
        });
    });

  };

  return (
    <div className="App" style={{ position: "relative" }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "65px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <PollIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            Ranking kosztu osobodnia
          </Typography>

        </div>
      </div>
      <p style={{ height: "5px" }} />
      {filtersLoaded ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            width: "100%",
          }}
        >
          <DateBox data={dateList} />
          <SortByBox />
          <MUITooltip title='Opis raportu'>
            <LibraryIcon
              id='reportInfoIcon'
              onClick={() => setReportInfoOpen(true)}
              style={{ marginTop: '15px', marginRight: '5px', cursor: 'pointer', width: '22px', height: '22px', fill: '#0b6471' }}
            />
          </MUITooltip>
          <MUITooltip title='Pobierz raport jako plik MS Excel'>
            <XlsxIcon
              style={{ marginTop: '15px', cursor: 'pointer', width: '22px', height: '22px', fill: '#0b6471' }}
              onClick={
                () => downloadFile(`${apiurl}/costperday/${startDate.year}/xlsx`)
              }
            />
          </MUITooltip>

        </div>
      ) : (
        <div>List not yet loaded</div>
      )}
      <p style={{ height: "5px" }} />

      {reportData ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "auto",
            transition: "opacity 0.5s",
            opacity: reportDataLoaded ? "1" : "0.2",
          }}
        >

          <ProceduresSummary row={reportData.sort((a, b) => (a[sortBy.id] > b[sortBy.id]) ? (sortBy.direction[0] === 'R' ? 1 : -1) : (sortBy.direction[0] === 'R' ? -1 : 1))} />


        </div>
      ) : (
        (
          <Typography
            style={{
              position: "absolute",
              top: "120%",
              left: "40%",
              color: "#0b6471",
              textJustify: "left",
            }}
            variant="h5"
          >
            Brak danych
          </Typography>
        )
      )}
      {!reportDataLoaded && (
        <CircularProgress
          style={{
            position: "absolute",
            height: "70px",
            width: "70px",
            top: reportData ? "50%" : "120%",
            left: "50%",
            color: "#0b6471",
            opacity: "0.6",
          }}
        />
      )}
      <Popover
        id='hintPopOver'
        open={reportInfoOpen}
        anchorEl={document.getElementById('reportInfoIcon')}
        onClose={() => setReportInfoOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
      >
        <Paper
          style={{
            padding: "10px",
            width: "600px",
            fontFamily: "roboto",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <div
            style={{
              top: "0px",
              display: "flex",
              width: "100%",
              justifyContent: "right"
            }}
          >
            <CloseIcon style={{ cursor: 'pointer', fontSize: '16px' }} onClick={() => setReportInfoOpen(false)} />
          </div>
          <div style={{ fontSize: '16px', padding: '5px', marginBottom: '5px' }}>
            <p>
              Raport zawiera <b>koszty osobodni</b> w podziale na miesiące.
            </p>
            <p>
              Jak liczony jest koszt osobodnia na oddziale?
            </p>
            <p>
              W koszcie osobodnia <b>uwzględnione są</b>:
            </p>
            <ul>
              <li>koszty bezpośrednie z <b>I etapu</b> alokacji kosztów (bez kosztów zaksięgowanych na kontach z grupy „401-02*”)</li>
              <li>doliczone koszty komórek pomocniczych (503, 535) z <b>II etapu</b> alokacji kosztów,</li>
              <li>doliczone koszty zarządu (550) z <b>IV etapu</b> alokacji kosztów.</li>
            </ul>
            <p>
              W koszcie osobodnia <b>nie są uwzględnione</b>:
            </p>
            <ul>
              <li>doliczone koszty procedur (507) z <b>III etapu</b> alokacji kosztów,</li>
              <li>koszty zaksięgowane na kontach z grupy <b>„401-02*”</b>.</li>
            </ul>
          </div>
          <div
            style={{
              top: "60px",
              display: "flex",
              justifyContent: "flex-end",
              borderTop: '1px #0B6471 solid'
            }}
          >
            <Button onClick={() => setReportInfoOpen(false)}>
              Zakończ
            </Button>
          </div>
        </Paper>
      </Popover>
    </div>
  );
}
