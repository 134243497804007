import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Typography from "@material-ui/core/Typography";
import PollIcon from "@material-ui/icons/Poll";
import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ProfitTable from './ProfitTable';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownload';
import ToolTip from "@material-ui/core/Tooltip";
import plLocale from "date-fns/locale/pl";
import DateFnsUtils from '@date-io/date-fns';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { apiurl } from '../app/prefs.js';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';

export default function Report022() {
  const [totalData, setTotalData] = React.useState([]);
  const [totalDataLoaded, setTotalDataLoaded] = React.useState(false);
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);
  const [dateList, setDateList] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const { getAccessTokenSilently, user } = useAuth0();

  const optionsData = [
    { value: 'single', label: 'Jednoetapowe' },
    { value: 'multi', label: <>Wieloetapowe</> },
    { value: 'single_v2', label: <> Jednoetapowe wer. 2</> },
    { value: 'single_v3', label: <> Wieloetapowe wer. 2</> },
    { value: 'multi_v5', label: <><sup style={{color: '#FE8E3C', fontWeight: 'bold', marginTop: '-10px'}}>N</sup> Wieloetapowe wer. 3</> },
  ]

  const [toggleFilter, setToggleFilter] = React.useState("single");
  const changeToggleFilter = (event, newOption) => {
    if (newOption && newOption !== toggleFilter) {
      setToggleFilter(newOption);
      loadData(startDate.getFullYear(), startDate.getMonth() + 1, newOption);
    }
  };
  const ToggleFilterButton = () => {
    return (
      optionsData ? <ToggleButtonGroup
        value={toggleFilter}
        exclusive
        size="small"
        onChange={changeToggleFilter}
        aria-label="text alignment"
      >
        {optionsData.map((option) => (
          <ToggleButton
            key={option.value}
            style={{ width: "200px", border: 'none', height: '30px' }}
            value={option.value}
            aria-label="left aligned"
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
        :
        null
    )
  }

  const loadData = (year, month, option) => {
    setTotalDataLoaded(false);
    const apiUrl = `${apiurl}/allocations/${year}/${month}/${option}`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          if (year) {
            //console.log(fetchedData)
            setTotalData(fetchedData);
            setTotalDataLoaded(true);
          }
        });
    });
  }

  React.useEffect(() => {
    let mounted = true;
    //console.log("Main mounted");
    loadFilters();
    return function cleanup() {
      //console.log("Main unmounted");
      mounted = false;
    };
  }, []);

  const loadFilters = () => {
    setFiltersLoaded(false);
    const apiUrl = `${apiurl}/periodslist`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          let listOfDates = fetchedData.CONTENT_SUMMARY.filter(item => item.version === 1).map(item => { return new Date(`${item.year}-${item.month}-01`) })
          setDateList(listOfDates);
          if (listOfDates && listOfDates.length !== 0) {
            setStartDate(listOfDates[listOfDates.length - 1]);
            loadData(listOfDates[listOfDates.length - 1].getFullYear(), listOfDates[listOfDates.length - 1].getMonth() + 1, toggleFilter);
            setFiltersLoaded(true);
          }
        });
    });
  }

  return (
    <div className="App" style={{ position: "relative" }}>
      <div
        style={{
          background: "linear-gradient(to right, #0b6471, #9BC0C5)",
          display: "flex",
          height: "55px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <PollIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            Eksport wyników rozliczeń
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      {totalDataLoaded ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            width: "100%",
            alignItems: 'flex-end',
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
            <KeyboardDatePicker
              style={{
                marginRight: "20px",
                width: "200px",
                justifyContent: "left",
              }}
              disableToolbar
              autoOk
              variant="inline"
              minDate={dateList[0]}
              maxDate={dateList[dateList.length - 1]}
              format="yyyy-MM"
              views={["month", "year"]}
              margin="dense"
              id="date-picker-inline"
              placeholder='Wybierz miesiąc'
              value={startDate}
              onChange={date => {
                setStartDate(date);
              }}
              //onAccept={date => loadData(date.getFullYear(), date.getMonth() + 1, toggleFilter)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <ToggleFilterButton />

          {true && <div style={{ position: "absolute", right: "15px" }}>
            <ToolTip title="Pobierz jako plik MS Excel" placement="top-start">
              <IconButton
                onClick={
                  () => {
                    getAccessTokenSilently().then((token) => {
                      document.body.style.cursor = 'wait';
                      fetch(`${apiurl}/allocationstoxls/${startDate.getFullYear()}/${startDate.getMonth() + 1}/${toggleFilter}`, {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          Authorization: "Bearer " + token,
                        },
                      })
                        .then((response) => response.blob())
                        .then((blob) => {
                          // Create blob link to download
                          const url = window.URL.createObjectURL(
                            new Blob([blob]),
                          );
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute(
                            'download',
                            `Rozliczenia.xlsx`,
                          );
                          // Append to html link element page
                          document.body.appendChild(link);
                          // Start download
                          link.click();
                          // Clean up and remove the link
                          link.parentNode.removeChild(link);
                          document.body.style.cursor = 'auto';
                        });
                    });
                  }
                }
              >
                <CloudDownloadOutlinedIcon />
              </IconButton>
            </ToolTip>
          </div>}
        </div>
      ) : (
        <div>Wczytywanie danych</div>
      )}
      <p style={{ height: "5px" }} />

      {totalData ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "auto",
            transition: "opacity 0.5s",
            opacity: totalDataLoaded ? "1" : "0.2",
          }}
        >
          <div
            id="top chart area"
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              id="left chart area"
              style={{
                display: "flex",
                flexWrap: "wrap",
                //maxWidth: "840px",
                width: "100%",
                flexDirection: "column",
                flexShrink: 1,
              }}
            >
              <div
                id="top 3 bottom 3 tables area"
                style={{
                  display: "flex",
                  paddingRight: "0px",
                  alignItems: "stretch",
                  flexWrap: "wrap",
                  width: "100%",
                  flexDirection: "row",
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                {totalData.allocations &&
                  <ProfitTable
                    title="ROZLICZENIA KOSZTÓW POMIĘDZY OPK"
                    data={totalData.allocations}
                    dataType="cost"
                    columns={totalData.headers}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        !totalData && (
          <Typography
            style={{
              position: "absolute",
              top: "120%",
              left: "40%",
              color: "#0b6471",
              textJustify: "left"
            }}
            variant="h5"
          >
            Wczytywanie danych
          </Typography>
        )
      )}
      {!totalDataLoaded && (
        <CircularProgress
          style={{
            position: "absolute",
            height: "70px",
            width: "70px",
            top: totalData[0] ? "50%" : "120%",
            left: "50%",
            color: "#0b6471",
            opacity: "0.6",
          }}
        />
      )}
    </div>
  );
}
