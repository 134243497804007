import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import PollIcon from "@material-ui/icons/Poll";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import CircularProgress from "@material-ui/core/CircularProgress";
import TinyChart from "./TinyChart";
import SummaryTable from "./SummaryTable";
import RankingChart from "./RankingChart";
import OPKTable from "./OPKTable";
import ProfitTable from "./ProfitTable";
import { apiurl } from '../app/prefs.js';
import MonthSelect from "./MonthSelection";
import ToolTip from "@material-ui/core/Tooltip";
import { ReactComponent as XlsxIcon } from './xlsx.svg';
import { Popover, Paper, Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const VideoIcon = (props) => (
  <svg
    style={{ ...props.style }}
    class="svg-icon"
    width='26px'
    height='26px'
    color='#0b6471'
    viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M725.333333 574.506667l170.666667 113.770666V335.722667l-170.666667 113.770666v125.013334z m0-227.562667l189.653334-126.442667C943.36 201.6 981.333333 221.930667 981.333333 256v512c0 34.069333-37.973333 54.4-66.346666 35.498667L725.333333 677.056v48.106667C725.333333 784.085333 677.504 832 618.752 832H149.248A106.666667 106.666667 0 0 1 42.666667 725.162667V298.837333C42.666667 239.914667 90.496 192 149.248 192h469.504A106.666667 106.666667 0 0 1 725.333333 298.837333v48.106667zM128 298.837333v426.325334A21.333333 21.333333 0 0 0 149.248 746.666667h469.504c11.584 0 21.248-9.685333 21.248-21.504V298.837333A21.333333 21.333333 0 0 0 618.752 277.333333H149.248C137.664 277.333333 128 287.018667 128 298.837333z" fill="#0b6471" /></svg>
);

const PlayIcon = () => (
  <svg width="40px" height="40px" viewBox="-1 0 14 14" id="meteor-icon-kit__solid-play-s" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.00154V13.0015C0 13.7788 0.84797 14.259 1.5145 13.859L11.5145 7.859C12.1618 7.4706 12.1618 6.5325 11.5145 6.1441L1.5145 0.14405C0.84797 -0.25586 0 0.22425 0 1.00154z" fill="#758CA3"></path></g></svg>
);

export default function Report002() {
  const [yardList, setYardList] = React.useState([]);
  const [dateList, setDateList] = React.useState([]);
  const [yardListLoaded, setYardListLoaded] = React.useState(false);
  const [activeYard, setActiveYard] = React.useState(null);
  const [reportDataLoaded, setReportDataLoaded] = React.useState(false);
  const [reportData, setReportData] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const [selectedMonths, setSelectedMonths] = React.useState([]);

  const loadData = (opk, year, months) => {
    setReportDataLoaded(false);
    const currentMonths = months ? months : selectedMonths;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(`${apiurl}/wardsdata/${opk.opk_id}/${year}/${JSON.stringify(currentMonths)}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          data && setReportData(data);
          setReportDataLoaded(true);
        });
    });
  }

  function loadDataWithMonths(year, months) {
    activeYard && loadData(activeYard, year, months)
  }

  function ComboBox(props) {
    return (
      <Autocomplete
        id="combo-box-demo"
        options={props.data}
        value={activeYard}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.opk_name}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.opk_name === value.opk_name;
        }}
        style={{ width: "550px", marginBottom: "10px" }}
        renderInput={(params) => <TextField {...params} label="Oddział" />}
        onChange={(event, value) => {
          if (value) {
            setActiveYard(value);
            loadData(value, startDate.year);
          }
        }}
      />
    );
  }

  function DateBox(props) {
    return (
      <Autocomplete
        id="combo-box"
        options={props.data}
        value={startDate}
        size="small"
        disableClearable
        autoHighlight
        getOptionLabel={(option) => option.year.toLocaleString()}
        defaultValue={null}
        getOptionSelected={(option, value) => {
          if (!value) return false;
          return option.year.toLocaleString() === value;
        }}
        style={{ width: "80px", marginBottom: "10px", marginLeft: '10px', marginRight: '10px' }}
        renderInput={(params) => <TextField {...params} label="Rok" />}
        onChange={(event, value) => {
          if (value) {
            setStartDate(value);
            activeYard && loadData(activeYard, value.year);
          }
        }}
      />
    );
  }

  React.useEffect(() => {
    let mounted = true;
    //console.log("Main mounted");
    setYardListLoaded(false);
    const apiUrl = `${apiurl}/yardslist`;
    getAccessTokenSilently().then((token) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      fetch(apiUrl, requestOptions)
        .then((res) => res.json())
        .then((fetchedData) => {
          if (mounted) {
            fetchedData.DATA && setYardList(fetchedData.DATA);
            fetchedData.datelist && setDateList(fetchedData.datelist);
            (fetchedData.datelist && fetchedData.datelist.length !== 0) && setStartDate(fetchedData.datelist[fetchedData.datelist.length - 1])
            setYardListLoaded(true);
          }
        });
    });
    return function cleanup() {
      //console.log("Main unmounted");
      mounted = false;
    };
  }, []);

  const [videoListOpen, setVideoListOpen] = React.useState(false);
  const [videoPlayDialogOpen, setVideoPlayDialogOpen] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState(undefined);

  const handleVideoListClose = () => {
    setVideoListOpen(false)
  };
  const videoData = [
    {
      image: '/manual/reports/2. Wyniki OPK - Oddziały.jpg',
      video: '/manual/reports/2. Wyniki OPK - Oddziały.mp4'
    }
  ];

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
    setVideoListOpen(false);
    setVideoPlayDialogOpen(true);
  };

  const VideoList = () => {
    return <div>
      {
        videoData.map(
          (v, i) => {
            return <div className="videoListItem" onClick={() => handleVideoSelect(v)}>
              <div style={{ position: 'relative' }}>
                <img src={v.image} alt={'Video nr ' + i} />
                <div style={{
                  position: 'absolute',
                  top: '90px',
                  left: '190px',
                  opacity: '.5',
                  color: 'gray'
                }}>
                  <PlayIcon />
                </div>
              </div>
            </div>
          }
        )
      }
    </div>
  }

  return (
    <div className="App" style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          height: "65px",
          width: "100%",
          backgroundColor: "#0b6471",
          color: "white",
          borderRadius: "5px",
          paddingTop: "0px",
          paddingLeft: "0px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", marginTop: "5px" }}>
          <PollIcon
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              fontSize: "42px",
            }}
          />
        </div>
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            WYNIKI OPK (GRUPA 504, 505, 506)
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "300", color: "white" }}
          >
            (BU, WYK) w zł.
          </Typography>
        </div>
      </div>
      <p style={{ height: "5px" }} />
      {yardListLoaded ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            width: "100%",
          }}
        >
          <ComboBox data={yardList} />
          <DateBox data={dateList} />
          <MonthSelect setSelectedMonthsFunction={setSelectedMonths} loadDataFunction={loadDataWithMonths} selectedMonthsProperty={selectedMonths} startDateProperty={startDate} />
          <ToolTip title='Opis raportu'>
            <a edge='end' style={{ marginTop: '14px', marginLeft: '7px', marginRight: '12px', color: '#0b6471' }} target='_blank' href='/usermanual/opis_raportu_005'>
              <ImportContactsIcon />
            </a>
          </ToolTip>
          <ToolTip title='Instrukcje video'>
            <div style={{ height: '36px', marginRight: '10px' }}
              onClick={
                () => {
                  setSelectedVideo(videoData[0]);
                  setVideoPlayDialogOpen(true);
                }
              }>
              <VideoIcon style={{ marginTop: '14px', cursor: 'pointer' }}></VideoIcon>
            </div>
          </ToolTip>
          <ToolTip title='Pobierz raport jako plik MS Excel'>
            <XlsxIcon
              style={{ marginTop: '15px', marginRight: '10px', cursor: (activeYard !== null) ? 'pointer' : 'default', width: '22px', height: '22px', fill: (activeYard !== null) ? '#0b6471' : 'gray' }}
              onClick={
                () => {
                  if (activeYard !== null) getAccessTokenSilently().then((token) => {
                    document.body.style.cursor = 'wait';
                    fetch(`${apiurl}/wardsdatatoxls/${activeYard.opk_id}/${startDate.year}/${JSON.stringify(selectedMonths)}`, {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        Authorization: "Bearer " + token,
                      },
                    })
                      .then((response) => response.blob())
                      .then((blob) => {
                        // Create blob link to download
                        const url = window.URL.createObjectURL(
                          new Blob([blob]),
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                          'download',
                          `Raport_OPK.xlsx`,
                        );
                        // Append to html link element page
                        document.body.appendChild(link);
                        // Start download
                        link.click();
                        // Clean up and remove the link
                        link.parentNode.removeChild(link);
                        document.body.style.cursor = 'auto';
                      });
                  });
                }
              }
            />
          </ToolTip>

        </div>
      ) : (
        <div>List not yet loaded</div>
      )}
      <p style={{ height: "5px" }} />

      {reportDataLoaded && activeYard && reportData.yearlydata && reportData.yearlydata.profit ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "auto",
            transition: "opacity 0.5s",
            opacity: reportDataLoaded ? "1" : "0.2",
          }}
        >
          <div
            id="top chart area"
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              id="left chart area"
              style={{
                display: "flex",
                flexWrap: "wrap",
                maxWidth: "840px",
                flexDirection: "column",
                flexShrink: 1,
              }}
            >
              {true &&
                <div
                  id="tiny charts area"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    flexDirection: "row",
                    flexShrink: 1,
                  }}
                >
                  <TinyChart
                    title="WYNIK"
                    data={reportData}
                    datatype="profit"
                    actual={
                      reportData.yearlydata.profit.toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      }) + " zł"
                    }
                    budget={
                      reportData.budgetdata.profit_BU && reportData.budgetdata.profit_BU.toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      }) + "%"
                    }
                    color="#4E97A5"
                  />
                  <TinyChart
                    title="PRZYCHODY"
                    data={reportData}
                    datatype="income"
                    actual={
                      reportData.yearlydata.income.toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      }) + " zł"
                    }
                    budget={
                      reportData.budgetdata.income_BU && reportData.budgetdata.income_BU.toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      }) + "%"
                    }
                    color="#BFFBFF"
                  />
                  <TinyChart
                    title="KOSZTY"
                    data={reportData}
                    datatype="cost"
                    actual={
                      reportData.yearlydata.cost.toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      }) + " zł"
                    }
                    budget={
                      reportData.budgetdata.cost_BU && reportData.budgetdata.cost_BU.toLocaleString("pl-PL", {
                        maximumFractionDigits: 0,
                      }) + "%"
                    }
                    color="#0B6471"
                  />
                </div>
              }
              {false &&
                <div
                  id="top 3 bottom 3 tables area"
                  style={{
                    display: "flex",
                    alignItems: "stretch",
                    flexWrap: "wrap",
                    width: "100%",
                    flexDirection: "row",
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  <SummaryTable
                    title="NAJBARDZIEJ RENTOWNE JGP"
                    data={reportData.jgp_results && reportData.jgp_results.slice(0, 3)}
                  />
                  <SummaryTable
                    title="NAJMNIEJ RENTOWNE JGP"
                    data={reportData.jgp_results && reportData.jgp_results.slice(-3)}
                  />
                </div>}
              {true &&
                <div
                  style={{ paddingRight: '10px' }}
                >
                  <ProfitTable
                    title="RANKING KOSZTÓW WYNAGRODZEŃ WG GRUP PRACOWNICZYCH"
                    data={reportData.salaries_by_group}
                    dataType="cost"
                    columns={[
                      {
                        name: "Grupa pracownicza",
                        align: "left",
                        style: { color: "#0b6471", borderBottom: "1px solid" },
                        datadef: "group_name",
                        datalen: "",
                      },
                      {
                        name: "BU",
                        align: "right",
                        style: { color: "#0b6471", borderBottom: "5px double" },
                        datadef: "cost_bu",
                        datalen: 0,
                      },
                      {
                        name: "Δ %",
                        align: "right",
                        style: { color: "#0b6471", borderBottom: "1px solid" },
                        datadef: "cost_delta",
                        datalen: 2,
                      },
                      {
                        name: "Koszt wynagrodzeń",
                        align: "right",
                        style: { color: "#0b6471", borderBottom: "4px solid" },
                        datadef: "cost",
                        datalen: 0,
                      },
                    ]}
                  />
                </div>
              }
            </div>
            {true &&
              <div
                id="right chart area"
                style={{
                  borderColor: "0px red solid",
                  display: "flex",
                  flexGrow: 1,
                  flexWrap: "wrap",
                  width: "220px",
                  flexDirection: "row",
                }}
              >
                <RankingChart
                  title="RANKING OPK WG WYNIKU"
                  color="#4E97A5"
                  data={reportData.opkranking}
                  opk_name={activeYard.opk_name}
                />
              </div>}
          </div>
          <OPKTable data={reportData} />
        </div>
      ) : (
        !activeYard && (
          <Typography
            style={{
              position: "absolute",
              top: "120%",
              left: "40%",
              color: "#0b6471",
              textJustify: 'left',
            }}
            variant="h5"

          >
            Wybierz komórkę
          </Typography>
        )
      )}
      {!reportDataLoaded && activeYard && (
        <CircularProgress
          style={{
            position: "absolute",
            height: "70px",
            width: "70px",
            top: reportData ? "50%" : "120%",
            left: "50%",
            color: "#0b6471",
            opacity: "0.6",
          }}
        />
      )}
      <Popover
        id='videoSelectPopOver'
        open={videoListOpen}
        anchorEl={document.getElementById('video')}
        onClose={handleVideoListClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
      >
        <Paper
          style={{
            padding: "10px",
            width: "450px",
            fontFamily: "roboto",
            display: "flex",
            flexDirection: "column",
            height: 'calc(100vh - 234px)',
          }}
        >

          <div
            style={{
              top: "0px",
              display: "flex",
              width: "100%",
              justifyContent: "right"
            }}
          >

            <CloseIcon style={{ cursor: 'pointer', fontSize: '16px' }} onClick={() => handleVideoListClose()} />

          </div>

          <VideoList />
        </Paper>
      </Popover>

      <Dialog

        maxWidth='90vw'
        open={videoPlayDialogOpen}
        onClose={() => setVideoPlayDialogOpen(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"

      >
        <DialogContent style={{ display: 'flex', flexDirection: 'column', margin: '0px', padding: '0px' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '3px 10px 3px 0px' }}>
            <button
              onClick={() => {
                const div = document.getElementById('videoControl');
                if (div.requestFullscreen)
                  div.requestFullscreen();
                else if (div.webkitRequestFullscreen)
                  div.webkitRequestFullscreen();
                else if (div.msRequestFullScreen)
                  div.msRequestFullScreen();
              }
              }
              style={{ backgroundColor: 'white', color: '#0b6471', fontWeight: 'bold', textDecorationLine: 'underline' }}
            >
              Pełen ekran
            </button>
            <button
              onClick={() => setVideoPlayDialogOpen(false)}
              style={{ backgroundColor: 'white', color: '#0b6471', fontWeight: 'bold', textDecorationLine: 'underline' }}
            >
              Zamknij
            </button>
          </div>
          <video id='videoControl' controls autoPlay style={{ width: '80vw' }}>
            <source src={selectedVideo && selectedVideo.video} type="video/mp4" />
            Video control not supported by browser
          </video>

        </DialogContent>
      </Dialog>
    </div>
  );
}
