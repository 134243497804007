import React, { Component } from "react";
import "./css/fw.css";
import "./css/m.fw.css";
import "./css/slides.css";
import { ImCalculator } from "react-icons/im";
import { GiHealthPotion } from "react-icons/gi";
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import CheckIcon from '@material-ui/icons/Check';
import { Link, animateScroll as scroll } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import MUILink from "@material-ui/core/Link";
import { Helmet } from "react-helmet";
import { withAuth0 } from "@auth0/auth0-react";

const klienci = [
  { name: 'Centrum Onkologii im. Prof. Franciszka Łukaszczyka', link: '/articles/standard_rachunku_kosztow_centrum_onkologii_bydgoszcz' },
  { name: 'Milickie Centrum Medyczne Sp. z o.o.' },
  { name: 'Przychodnie Lekarskie Hipokrates Spółka z o.o.' },
  { name: 'SP ZOZ Zespół Opieki Zdrowotnej w Głuchołazach' },
  { name: 'Samodzielny Publiczny Zakład Opieki Zdrowotnej w Radziejowie' },
  { name: 'Samodzielny Publiczny Zakład Opieki Zdrowotnej w Siennicy' },
  { name: 'Samodzielny Publiczny Zakład Opieki Zdrowotnej w Żukowie', link: '/articles/standard_rachunku_kosztow_w_spzoz_zukowo' },
  { name: 'Szpital Kliniczny im. K. Jonschera Uniwersytetu Medycznego w Poznaniu' },
  { name: 'Szpitale Tczewskie S.A.' },
  { name: 'Zespół Przychodni i Ośrodków Zdrowia Krzeszowickie Centrum Zdrowia' },

];

let slideIndex = 0;
var timeout = undefined;

function showSlides() {
  let i;
  let slides = document.getElementsByClassName("reference-mySlides");
  let dots = document.getElementsByClassName("reference-dot");
  if (slides === undefined || dots === undefined || slides.length === 0 || dots.length === 0) return(null);
  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }
  slideIndex++;
  if (slideIndex > slides.length) { slideIndex = 1 }
  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(" reference-active", "");
  }
  slides[slideIndex - 1].style.display = "block";
  dots[slideIndex - 1].className += " reference-active";
  timeout = setTimeout(showSlides, 5000); // Change image every 2 seconds
}

function setSlide(newId) {
  let i;
  if (newId === slideIndex) return (null);
  let slides = document.getElementsByClassName("reference-mySlides");
  let dots = document.getElementsByClassName("reference-dot");
  if (slides === undefined || dots === undefined || slides.length === 0 || dots.length === 0) return(null);
  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }
  slideIndex = newId;
  if (slideIndex > slides.length) { slideIndex = 1 }
  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(" reference-active", "");
  }
  slides[slideIndex - 1].style.display = "block";
  dots[slideIndex - 1].className += " reference-active";
  clearTimeout(timeout);
  timeout = setTimeout(showSlides, 7000); // Change image every 2 seconds
}

class Home extends Component {

  componentDidMount() {
    // Runs after the first render() lifecycle
    showSlides();
  }

  render() {

    const { isLoading } = this.props.auth0;

    function filterSelection(element, c) {
      var x, i;

      x = document.getElementsByClassName("filterDiv");
      if (c === "all") c = "";
      // Add the "show" class (display:block) to the filtered elements, and remove the "show" class from the elements that are not selected
      for (i = 0; i < x.length; i++) {
        w3RemoveClass(x[i], "show");
        if (x[i].className.indexOf(c) > -1) w3AddClass(x[i], "show");
      }

      var current = document.getElementsByClassName("actived");
      if (current[0])
        current[0].className = current[0].className.replace(" actived", "");
      element.className += " actived";
    }

    // Show filtered elements
    function w3AddClass(element, name) {
      var i, arr1, arr2;
      arr1 = element.className.split(" ");
      arr2 = name.split(" ");
      for (i = 0; i < arr2.length; i++) {
        if (arr1.indexOf(arr2[i]) === -1) {
          element.className += " " + arr2[i];
        }
      }
    }

    // Hide elements that are not selected
    function w3RemoveClass(element, name) {
      var i, arr1, arr2;
      arr1 = element.className.split(" ");
      arr2 = name.split(" ");
      for (i = 0; i < arr2.length; i++) {
        while (arr1.indexOf(arr2[i]) > -1) {
          arr1.splice(arr1.indexOf(arr2[i]), 1);
        }
      }
      element.className = arr1.join(" ");
    }

    if (isLoading) {
      return <div>Loading </div>
    }

    return (
      <div
        style={{ backgroundColor: "white", margin: "-5px", padding: "10px", zIndex: 840 }}
        id="panel1"
      >

        <div
          style={{ backgroundColor: "rgb(255,255,255", zIndex: 840 }}
          data-collapse="medium"
          data-animation="default"
          data-duration="400"
          role="banner"
          className="navigation w-nav"
        >
          <div
            className="w-container"
            style={{ backgroundColor: "rgb(255,255,255" }}
          >
            <nav
              role="navigation"
              className="nav-menu w-nav-menu"
              style={{ backgroundColor: "rgb(255,255,255" }}
            >
              <Link
                className="nav-link w-nav-link"
                href="#aplikacja"
                to="aplikacja"
                smooth={true}
                duration={700}
                style={{ cursor: "pointer" }}
              >
                O Aplikacji
              </Link>
              <Link
                className="nav-link w-nav-link"
                to="Klienci"
                href="#Klienci"
                smooth={true}
                offset={-40}
                duration={1000}
                style={{ cursor: "pointer" }}
              >
                Nasi Klienci
              </Link>
              <MUILink
                component={RouterLink}
                to="/articles"
                style={{ cursor: "pointer", textDecoration: 'none' }}
              >
                Publikacje
              </MUILink>
              <Link
                className="nav-link w-nav-link"
                to="cena"
                href="#cena"
                smooth={true}
                offset={-30}
                duration={1000}
                style={{ cursor: "pointer" }}
              >
                Cena
              </Link>
              <Link
                className="nav-link w-nav-link"
                to="kontakt"
                href="#kontakt"
                smooth={true}
                duration={1000}
                style={{ cursor: "pointer" }}
              >
                Kontakt
              </Link>
            </nav>
          </div>
        </div>

        <div id="top" className="section main">
          <div className="w-container">
            <div className="w-row">
              <div className="w-col">
                <div style={{ fontSize: "46px", fontWeight: 'bold', marginBottom: "24px", lineHeight: '52px' }}>
                  Spełnij wymogi standardu rachunku kosztów i wyceń procedury medyczne z CO<font color="#FE8E3C">+</font> !
                </div>
                <p style={{ color: "white" }} className="main-subtitle">
                  CO<font color="#FE8E3C">+</font> to program umożliwiający uruchomienie standardu rachunku kosztów zgodnie z  wytycznymi rozporządzenia MZ (Dz.U. z 2020 r., poz. 2045).
                </p>
              </div>

            </div>
            <div className="w-row">

              <div className="w-col">
                <div style={{ display: 'flex' }}>
                  <video
                    id='videoControl' controls style={{ width: '100%', borderRadius: '8px' }}
                    poster='/open/0. Reklama.jpg'
                    preload="none"
                  >
                    <source src='/open/0. Reklama.mp4' type="video/mp4" />
                    Twoja przeglądarka nie obsługuje odtwarzania wideo :(
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section press">
          <div className="columns-2 w-row">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div style={{ flex: 1 }} className="feature">
                <div style={{ color: '#FE8E3C', fontSize: '64px', fontWeight: 'bold', lineHeight: '60px' }}>+</div>
                <h3 style={{ fontWeight: "100" }} className="heading-2">
                  <strong>Standard Rachunku Kosztów</strong>
                </h3>
              </div>

              <div style={{ flex: 1 }} className="feature">
                <div style={{ color: '#FE8E3C', fontSize: '64px', fontWeight: 'bold', lineHeight: '60px' }}>+</div>
                <h3 style={{ fontWeight: "100" }} className="heading-3">
                  <strong>Biblioteka Opisanych Procedur</strong>
                </h3>
              </div>

              <div style={{ flex: 1 }} className="feature">
                <div style={{ color: '#FE8E3C', fontSize: '64px', fontWeight: 'bold', lineHeight: '60px' }}>+</div>
                <h3 style={{ fontWeight: "100" }} className="heading">
                  <strong>Raporty Zarządcze</strong>
                </h3>
              </div>

            </div>
          </div>
        </div>
        <div id="aplikacja" className="section">
          <div className="w-container">

            <div style={{ fontSize: '32px', fontWeight: 'normal', marginTop: '20px', marginBottom: '30px', color: '#0b6471' }}>
              Spełnij wymogi standardu rachunku kosztów
            </div>

            <div className="feature-row w-row" style={{ display: 'flex', marginRight: '0px', marginLeft: '0px' }}>
              <div style={{ width: '50%', paddingRight: '10px' }}>
                <div style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px', color: '#0b6471' }}>
                  Czy  z punktu widzenia zarządzania podmiotem medycznym istotne są odpowiedzi na pytania takie jak:
                </div>
                <ul style={{ fontSize: '18px' }}>
                  <li>
                    Jaka jest rentowność podmiotu, konkretnej poradni czy oddziału?
                  </li>
                  <li>
                    Jakie ceny powinny znaleźć się w cenniku usług zewnętrznych?
                  </li>
                  <li>
                    Ile kosztują wykonywane w podmiocie procedury medyczne?
                  </li>
                  <li>
                    Ile wynosi koszt osobodnia?
                  </li>
                  <li>
                    Ile wynoszą koszty wynagrodzeń w podziale na grupy pracownicze?
                  </li>
                  <li>
                    Jak skrócić czas przygotowania raportów zarządczych do kilku sekund?
                  </li>
                </ul>
              </div>
              <div style={{ width: '50%' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <img
                    src="./images/rap.png"
                    data-ix="fade-in-on-scroll"
                    alt=""
                    className="transparentimage"
                    style={{ height: '320px', width: '100%' }}
                  />
                </div>
                <div style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '10px', color: '#0b6471' }}>
                  Oprogramowanie CO+ zapewnia gotowe raporty, pozwalające na znalezienie odpowiedzi na powyższe pytania w kilka sekund!
                </div>
              </div>
            </div>

          </div>
        </div>

        <div id="aplikacja_2" className="section" style={{ backgroundColor: '#4E97A5' }}>
          <div className="w-container">
            <div style={{ fontSize: '32px', fontWeight: 'normal', marginTop: '20px', marginBottom: '30px', color: 'white' }}>
              Wyceń procedury medyczne z CO+
            </div>
            <div style={{ fontSize: '24px', fontWeight: '', marginBottom: '20px', color: 'white' }}>
              Skorzystaj z Biblioteki Opisanych Procedur i wyceń procedury zgodnie z wymogami rozporządzenia MZ
            </div>
            <div className="feature-row w-row" style={{ display: 'flex', marginRight: '0px', marginLeft: '0px', width: '100%' }}>
              <div style={{ width: '50%', paddingRight: '0px', display: 'flex', marginRight: '10px', backgroundColor: '#fbfbfb', borderRadius: '10px', alignItems: 'center' }}>
                <div style={{ marginBottom: '0px', borderRadius: '0px 0px 0px 0px', width: '100%' }}>
                  <a href='/procedury_wstep' target='_blank'>
                    <img
                      src="./manual/0_Procedury_wstep.jpg"
                      alt="COplus"
                      style={{
                        width: "100%",
                        height: "100%",

                      }}
                    ></img>
                  </a>
                </div>
              </div>
              <div style={{ width: '50%' }}>
                <div style={{ fontSize: '18px', color: 'white' }}>
                  Przypisz zasoby materiałowe i osobowe do procedur
                  <br /><br />
                  Skorzystaj z zestawu przydatnych funkcji jak kopiowanie opisu pomiędzy procedurami oraz Biblioteki Opisanych Procedur
                  <br /><br />
                  CO+ samodzielnie obliczy koszt wytworzenia procedur medycznych, w oparciu o jednostkę kalkulacyjną lub proporcjonalnie do czasu trwania procedur (każdy OPK może mieć wybraną inną metodę).
                </div>
              </div>
            </div>

          </div>
        </div>


        <div id="aplikacja_3" className="section">
          <div className="w-container">
            <div style={{ fontSize: '32px', fontWeight: 'normal', marginTop: '20px', marginBottom: '20px', color: '#0b6471' }}>
              Poznaj CO+
            </div>
            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '30px' }}>
              <div style={{ flex: '1 1 0px', marginRight: '30px', boxShadow: '5px 5px 10px lightgray', borderRadius: '0px 25px 0px 0px' }}>
                <div style={{ height: '160px', marginBottom: '20px', backgroundColor: '#0b6471', borderRadius: '0px 25px 0px 0px' }}>
                  <img
                    src="./images/1laptop.jpg"
                    alt="COplus"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: '0px 25px 0px 0px'
                    }}
                  ></img>
                </div>
                <div style={{ padding: '5px', fontSize: '20px', fontWeight: 'bold', marginBottom: '10px', color: '#0b6471' }}>
                  Integracja i interfejs
                </div>
                <div style={{ fontSize: '16px', padding: '5px' }}>
                  Dane do CO+ importowane są przy pomocy prostego pliku MS Excel, co daje gwarancję przejrzystości importowanych zestawień.
                  <br /><br />
                  CO+ współpracuje z dowolnym systemem FK oraz dowolnym programem medycznym
                  <br /><br />
                  CO+ posiada prosty, intuicyjny interfejs użytkownika.
                </div>
              </div>

              <div style={{ flex: '1 1 0px', marginRight: '30px', boxShadow: '5px 5px 10px lightgray', borderRadius: '0px 25px 0px 0px' }}>
                <div style={{ height: '160px', marginBottom: '20px', backgroundColor: '#0b6471', borderRadius: '0px 25px 0px 0px' }}>
                  <img
                    src="./images/2security.jpg"
                    alt="COplus"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: '0px 25px 0px 0px'
                    }}
                  ></img>
                </div>
                <div style={{ padding: '5px', fontSize: '20px', fontWeight: 'bold', marginBottom: '10px', color: '#0b6471' }}>
                  Bezpieczeństwo
                </div>
                <div style={{ fontSize: '16px', padding: '5px' }}>
                  Serwery COplus znajdują się w Google Cloud - bezpiecznej chmurze obliczeniowej firmy Google.
                  <br /><br />
                  Bezpieczeństwo rozwiązań Google Cloud potwierdzone jest szeregiem międzynarodowych certyfikatów bezpieczeństwa, takich jak <strong>ISO/IEC 27001, SOC 1/2/3, HIPAA.</strong>
                  <br /><br />
                  Komunikacja z aplikacją jest szyfrowana.
                </div>
              </div>

              <div style={{ flex: '1 1 0px', marginRight: '0px', boxShadow: '5px 5px 10px lightgray', borderRadius: '0px 25px 0px 0px' }}>
                <div style={{ height: '160px', marginBottom: '20px', backgroundColor: '#0b6471', borderRadius: '0px 25px 0px 0px' }}>
                  <img
                    src="./images/scienceEducation.jpg"
                    alt="COplus"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: '0px 25px 0px 0px'
                    }}
                  ></img>
                </div>
                <div style={{ padding: '5px', fontSize: '20px', fontWeight: 'bold', marginBottom: '10px', color: '#0b6471' }}>
                  Wsparcie
                </div>
                <div style={{ fontSize: '16px', padding: '5px' }}>
                  CO+ krok po kroku przeprowadza przez procesu uruchomienia Standardu Rachunku Kosztów.

                  <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '5px', marginTop: '10px', color: '#0b6471' }}>
                    Wsparcie merytoryczne
                  </div>
                  <ul style={{ fontSize: '16px' }}>
                    <li>Weryfikacja planu 4 i OPK</li>
                    <li>Baza Opisanych Procedur</li>
                    <li>Baza Wiedzy (<a href='/articles' target='_blank'>przejdź</a>)</li>
                  </ul>
                  <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '5px', marginTop: '10px', color: '#0b6471' }}>
                    Wsparcie techniczne
                  </div>
                  <ul style={{ fontSize: '16px' }}>
                    <li>CO+ samodzielnie, w kilka sekund, wykonuje alokacje kosztów</li>
                    <li>CO+ to również zestaw, gotowych raportów zarządczych, w tym raport FK.OPK (AOTMiT)</li>

                  </ul>
                </div>

              </div>

            </div>

          </div>
        </div>


        <div id="knowledge_2" className="section" style={{ backgroundColor: '#dde7ed' }}>
          <div className="w-container">

            <div>
              <div style={{ fontSize: '32px', fontWeight: 'normal', marginTop: '10px', marginBottom: '20px', color: '#FE8E3C' }}>
                Baza wiedzy
              </div>

              <div>
                <div className="btn_container" style={{ justifyContent: 'space-between' }}>
                  <div
                    id="myBtnContainer"
                    style={{ marginBottom: "10px", marginTop: "20px" }}
                  ></div>
                  <buttont
                    style={{ flex: "1 1 0px", fontSize: '18px', fontWeight: 'bold', color: 'white', backgroundColor: '#0B6471', borderRadius: '0px 15px 0px 0px' }}
                    className="btn actived"

                    onClick={(e) => {
                      filterSelection(e.currentTarget, "wycena_procedur");
                    }}
                  >
                    Wycena procedur
                  </buttont>
                  <buttont
                    className="btn"
                    style={{ flex: "1 1 0px", fontSize: '18px', fontWeight: 'bold', color: 'white', backgroundColor: '#0B6471', borderRadius: '0px 15px 0px 0px' }}
                    onClick={(e) => {
                      filterSelection(e.currentTarget, "rachunek_kosztow");
                    }}
                  >
                    Rachunek kosztów
                  </buttont>
                  <buttont
                    className="btn"
                    style={{ flex: "1 1 0px", fontSize: '18px', fontWeight: 'bold', color: 'white', backgroundColor: '#0B6471', borderRadius: '0px 15px 0px 0px' }}
                    onClick={(e) => {
                      filterSelection(e.currentTarget, "rozporzadzenie");
                    }}
                  >
                    Rozporządzenie
                  </buttont>
                </div>
                <div className="container" style={{ fontSize: '16px' }}>
                  <li className="filterDiv wycena_procedur show">
                    <GiHealthPotion
                      style={{
                        color: "#FE8E3C",
                        minWidth: "12px",
                        maxWidth: "12px",
                        minHeight: "12px",
                        maxHeight: "12px",
                        marginRight: "8px",
                      }}
                    />
                    <MUILink
                      component={RouterLink}
                      to="/articles/ile_kosztuja_procedury_medyczne"
                    >
                      Ile kosztują procedury medyczne?
                    </MUILink>
                  </li>
                  <li className="filterDiv wycena_procedur show">
                    <GiHealthPotion
                      style={{
                        color: "#FE8E3C",
                        minWidth: "12px",
                        maxWidth: "12px",
                        minHeight: "12px",
                        maxHeight: "12px",
                        marginRight: "8px",
                      }}
                    />
                    <MUILink
                      component={RouterLink}
                      to="/articles/ile_kosztuja_pracownicy_zaangazowani_w_realizacje_procedur"
                    >
                      Ile kosztują pracownicy zaangażowani w realizację procedur
                      medycznych?
                    </MUILink>
                  </li>
                  <li className="filterDiv wycena_procedur show">
                    <GiHealthPotion
                      style={{
                        color: "#FE8E3C",
                        minWidth: "12px",
                        maxWidth: "12px",
                        minHeight: "12px",
                        maxHeight: "12px",
                        marginRight: "8px",
                      }}
                    />
                    <MUILink
                      component={RouterLink}
                      to="/articles/ile_kosztuja_materialy_wykorzystywane_przy_realizacji_procedur"
                    >
                      Ile kosztują materiały zaangażowane w realizację procedur
                      medycznych
                    </MUILink>
                  </li>
                  <li className="filterDiv rozporzadzenie">
                    <div
                      style={{
                        color: "#FE8E3C",
                        fontSize: "14px",
                        marginTop: "-4px",
                        marginRight: "8px",
                      }}
                    >
                      §
                    </div>
                    Rozporządzenie MZ w sprawie standardu rachunku kosztów u
                    świadczeniodawców
                  </li>
                  <li className="filterDiv rozporzadzenie">
                    <div
                      style={{
                        color: "#FE8E3C",
                        fontSize: "14px",
                        marginTop: "-4px",
                        marginRight: "8px",
                      }}
                    >
                      §
                    </div>
                    Załącznik 1 – kody funkcji OPK{" "}
                  </li>
                  <li className="filterDiv rozporzadzenie">
                    <div
                      style={{
                        color: "#FE8E3C",
                        fontSize: "14px",
                        marginTop: "-4px",
                        marginRight: "8px",
                      }}
                    >
                      §
                    </div>
                    Załącznik 2 – zasady wyodrębniania OPK
                  </li>
                  <li className="filterDiv rachunek_kosztow">
                    <ImCalculator
                      style={{
                        color: "#FE8E3C",
                        minWidth: "12px",
                        maxWidth: "12px",
                        minHeight: "12px",
                        maxHeight: "12px",
                        marginRight: "8px",
                      }}
                    />
                    Lista dostępnych raportów wraz z komentarzem
                  </li>
                  <li className="filterDiv rachunek_kosztow">
                    <ImCalculator
                      style={{
                        color: "#FE8E3C",
                        minWidth: "12px",
                        maxWidth: "12px",
                        minHeight: "12px",
                        maxHeight: "12px",
                        marginRight: "8px",
                      }}
                    />
                    Zasada kalkulacji kosztu osobodnia
                  </li>
                  <li className="filterDiv rachunek_kosztow">
                    <ImCalculator
                      style={{
                        color: "#FE8E3C",
                        minWidth: "12px",
                        maxWidth: "12px",
                        minHeight: "12px",
                        maxHeight: "12px",
                        marginRight: "8px",
                      }}
                    />
                    Kalkulacja kosztu świadczenia opieki zdrowotnej
                  </li>
                  <li className="filterDiv wycena_procedur show">
                    <GiHealthPotion
                      style={{
                        color: "#FE8E3C",
                        minWidth: "12px",
                        maxWidth: "12px",
                        minHeight: "12px",
                        maxHeight: "12px",
                        marginRight: "8px",
                      }}
                    />
                    <a style={{ cursor: 'pointer', textDecoration: 'none' }} href='./articles'>...</a>
                  </li>
                  <li className="filterDiv rachunek_kosztow">
                    <ImCalculator
                      style={{
                        color: "#FE8E3C",
                        minWidth: "12px",
                        maxWidth: "12px",
                        minHeight: "12px",
                        maxHeight: "12px",
                        marginRight: "8px",
                      }}
                    />
                    <a style={{ cursor: 'pointer', textDecoration: 'none' }} href='./articles'>...</a>
                  </li>
                  <li className="filterDiv rozporzadzenie">
                    <div
                      style={{
                        color: "#FE8E3C",
                        fontSize: "14px",
                        marginTop: "-4px",
                        marginRight: "8px",
                      }}
                    >
                      §
                    </div>
                    <a style={{ cursor: 'pointer', textDecoration: 'none' }} href='./articles'>...</a>
                  </li>
                </div>
              </div>

              <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '30px' }}>
                <div style={{ flex: '1 1 0px', marginRight: '30px', boxShadow: '5px 5px 10px lightgray', borderRadius: '0px 25px 0px 0px' }}>
                  <div style={{ height: '240px', marginBottom: '0px', backgroundColor: '#0b6471', borderRadius: '0px 25px 0px 0px' }}>
                    <a href='/raporty' target='_blank'>
                      <img
                        src="./open/00. Raporty w CO+.jpg"
                        alt="COplus"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: '0px 25px 0px 0px'
                        }}
                      ></img>
                    </a>
                  </div>
                </div>

                <div style={{ flex: '1 1 0px', marginRight: '0px', boxShadow: '5px 5px 10px lightgray', borderRadius: '0px 25px 0px 0px' }}>
                  <div style={{ height: '240px', marginBottom: '0px', backgroundColor: '#0b6471', borderRadius: '0px 25px 0px 0px' }}>
                    <a href='/procedury_wstep' target='_blank'>
                      <img
                        src="./manual/0_Procedury_wstep.jpg"
                        alt="COplus"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: '0px 25px 0px 0px'
                        }}
                      ></img>
                    </a>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>


        <div id="Klienci" className="section">
          <div className="w-container">
            <div style={{ fontSize: '32px', fontWeight: 'normal', marginTop: '10px', marginBottom: '20px', color: '#0b6471' }}>
              Nasi Klienci
            </div>

            <br />
            <p style={{ fontSize: '18px', marginBottom: '10px' }}>
              Z aplikacji CO+ korzysta ponad 400 aktywnych użytkowników, wśród nich:
            </p>
            <ul style={{ paddingLeft: '10px' }}>
              {klienci.sort((a, b) => a.name > b.name)
                .map(item =>
                  <div style={{ display: 'flex', alignItems: 'flex-start', fontSize: '16px' }}>
                    <CheckIcon style={{ marginRight: '10px', color: '#0b6471', marginBottom: '4px' }} />
                    <p style={{ textAlign: 'left' }}>{item.name}</p>
                    {item.link && <a
                      style={{
                        fontSize: '10px',
                        textDecoration: 'none',
                        backgroundColor: '#FE8E3C',
                        borderRadius: '7px',
                        paddingLeft: '3px',
                        paddingRight: '3px',
                        marginBottom: '3px',
                        color: 'white',
                        marginLeft: '10px'
                      }} href={item.link}>opis wdrożenia</a>}
                  </div>)}
            </ul>

            <div className="reference-slideshow-container">

              <div className="reference-mySlides reference-fade">
                <div className="referenceText">
                  „Początkowo myśleliśmy, że wykonanie opisu procedur medycznych to nierealne zadanie. Korzystając ze
                  wskazówek oraz biblioteki przykładowych opisów procedur, zawartych w programie CO+, udało nam się opisać
                  ponad 500 procedur.”
                </div>
                <div className="referenceAuthor">
                  SZPZLO Warszawa Praga Południe
                </div>
              </div>

              <div className="reference-mySlides reference-fade">
                <div className="referenceText">
                  „Korzystamy w aplikacji CO+ do rozliczania kosztów zgodnie z wymogami standardu rachunku kosztów.
                  Uruchomienie przebiegło bardzo sprawnie. Dzięki dostępnym raportom zauważyliśmy obszary, które wymagały
                  zmiany w ewidencji zarówno kosztów, przychodów oraz procedur."
                </div>
                <div className="referenceAuthor">
                  Szpital Powiatowy Sp. z o.o. w Pasłęku
                </div>
              </div>

              <div className="reference-mySlides reference-fade">
                <div className="referenceText">
                  „Bardzo pozytywnie odbieramy sposób weryfikacji poprawności danych wykonywany przez CO+, co daje nam
                  pewność,
                  że koszty rozliczyły się zgodnie z wymogami SRK. Dodatkowo dostępne w CO+ raporty zarządcze w
                  przejrzysty
                  sposób pokazały obszary wymagające obecnie naszej większej uwagi."
                </div>
                <div className="referenceAuthor">
                  Dział Controlingu, Wojewódzki Szpital Zespolony w Lesznie
                </div>
              </div>

              <div className="reference-mySlides reference-fade">
                <div className="referenceText">
                  „Import danych, weryfikacja ich poprawności oraz rozliczenia kosztów przebiegają błyskawicznie. Raporty
                  CO+
                  wykorzystujemy do aktualizacji kosztów wykonywanych procedur w systemie medycznym.”
                </div>
                <div className="referenceAuthor">
                  Szpital Powiatowy w Rawiczu Sp. z o.o.
                </div>
              </div>

              <div className="reference-mySlides reference-fade">
                <div className="referenceText">
                  „Dzięki aplikacji CO+ bardzo szybko i sprawnie uruchomiliśmy nowy standard rachunku kosztów i okazało
                  się, że
                  nie jest to takie trudne. Uporządkowaliśmy nasze zbiory danych i od tej chwili zarówno alokacja kosztów,
                  jak
                  i wycena procedur medycznych nie stanowią dla nas problemu."
                </div>
                <div className="referenceAuthor">
                  Szpital Kliniczny im. Ks. Anny Mazowieckiej
                </div>
              </div>

              <div className="reference-mySlides reference-fade">
                <div className="referenceText">
                  „Przy wsparciu konsultantów z firmy COplus udało się wykonać ogrom prac i w ciągu zaledwie 6
                  tygodni w Centrum Onkologii w Bydgoszczy wdrożono standard rachunku kosztów."
                </div>
                <div className="referenceAuthor">
                  Centrum Onkologii im. prof. F. Łukaszczyka w Bydgoszczy
                </div>
              </div>

            </div>
            <br />

            <div style={{ textAlign: 'center' }}>
              <span onClick={() => setSlide(1)} className="reference-dot"></span>
              <span onClick={() => setSlide(2)} className="reference-dot"></span>
              <span onClick={() => setSlide(3)} className="reference-dot"></span>
              <span onClick={() => setSlide(4)} className="reference-dot"></span>
              <span onClick={() => setSlide(5)} className="reference-dot"></span>
              <span onClick={() => setSlide(6)} className="reference-dot"></span>
            </div>

          </div>
        </div>


        <div id="cena" className="section purple">
          <div className="w-container" style={{ maxWidth: '1300px' }} >
            <div style={{ maxWidth: '940px', margin: 'auto' }}>
              <div style={{ fontSize: '32px', fontWeight: 'normal', marginTop: '10px', marginBottom: '0px', color: '#0B6471' }}>
                Cena
              </div>
              <div style={{ fontSize: '18px', fontWeight: 'normal', marginTop: '0px', marginBottom: '20px', color: '#0B6471' }}>
                Opłata za dostęp do aplikacji CO+ (dotyczy dostępu dla jednego miejsca udzielania świadczeń)
              </div>
            </div>

            <div className="w-row" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '15px' }}>

              <div className="w-col w-col-4" style={{ maxWidth: '350px', minWidth: '350px', textAlign: 'left' }}>
                <div data-ix="fade-in-on-scroll" className="plan-wrapper">
                  <h3 style={{ marginBottom: '15px' }}>
                    <img
                      src="./logo192.svg"
                      alt="COplus"
                      style={{
                        width: "50px",
                        height: "50px",
                        marginRight: "5px",
                        padding: "0px",
                        marginTop: "-3px",
                      }}
                    ></img>
                    Rozporządzenie
                  </h3>
                  <div style={{ marginTop: '-30px', marginBottom: '10px', marginLeft: '55px' }}>Przychodnia, Pogotowie, Hospicjum</div>
                  <div className="price-point">
                    <strong className="price-accent"> 790 zł netto / miesiąc</strong>
                  </div>
                  <div className="price-point">
                    Dostęp dla maksymalnie 5-ciu użytkowników z podmiotu, zakup
                    licencji na minimum 1 rok
                  </div>
                  <div className="divider"></div>
                  <div className="addl-feature">
                    <strong>Wsparcie konsultantów COplus</strong>
                  </div>
                  <div className="addl-feature">
                    <strong>Dostęp do bazy wiedzy</strong>
                  </div>
                  <div className="addl-feature">
                    <strong>Moduł kosztów i rentowności OPK</strong>
                  </div>
                  <div className="addl-feature">
                    <strong>Moduł wyceny procedur</strong>
                  </div>
                  <div className="addl-feature">
                    <strong>Moduł raportowy</strong>
                  </div>
                  <div className="addl-feature inactive">
                    Moduł wyceny pacjenta
                  </div>
                  <a
                    href='/register/rozporzadzenie'
                    className="button in-pricing"
                  >
                    Zamów
                  </a>
                </div>
              </div>

              <div className="w-col w-col-4" style={{ maxWidth: '350px', minWidth: '350px', textAlign: 'left' }}>
                <div data-ix="fade-in-on-scroll" className="plan-wrapper">
                  <h3 style={{ marginBottom: '15px' }}>
                    <img
                      src="./logo192.svg"
                      alt="COplus"
                      style={{
                        width: "50px",
                        height: "50px",
                        marginRight: "5px",
                        padding: "0px",
                        marginTop: "-3px",
                      }}
                    ></img>
                    Rozporządzenie
                  </h3>
                  <div style={{ marginTop: '-30px', marginBottom: '10px', marginLeft: '55px' }}>Szpital, Uzdrowisko</div>
                  <div className="price-point">
                    <strong className="price-accent"> 1590 zł netto / miesiąc</strong>
                  </div>
                  <div className="price-point">
                    Dostęp dla maksymalnie 10-ciu użytkowników z podmiotu, zakup
                    licencji na minimum 1 rok
                  </div>
                  <div className="divider"></div>
                  <div className="addl-feature">
                    <strong>Wsparcie konsultantów COplus</strong>
                  </div>
                  <div className="addl-feature">
                    <strong>Dostęp do bazy wiedzy</strong>
                  </div>
                  <div className="addl-feature">
                    <strong>Moduł kosztów i rentowności OPK</strong>
                  </div>
                  <div className="addl-feature">
                    <strong>Moduł wyceny procedur</strong>
                  </div>
                  <div className="addl-feature">
                    <strong>Moduł raportowy</strong>
                  </div>
                  <div className="addl-feature inactive">
                    Moduł wyceny pacjenta
                  </div>
                  <a
                    href='/register/rozporzadzenie'
                    className="button in-pricing"
                  >
                    Zamów
                  </a>
                </div>
              </div>

              <div className="w-col w-col-4" style={{ maxWidth: '350px', minWidth: '35px', textAlign: 'left' }}>
                <div data-ix="fade-in-on-scroll" className="plan-wrapper">
                  <h3 style={{ marginBottom: '15px' }}>
                    <img
                      src="./logo192.svg"
                      alt="COplus"
                      style={{
                        width: "50px",
                        height: "50px",
                        marginRight: "5px",
                        padding: "0px",
                        marginTop: "-3px",
                      }}
                    ></img>
                    Zaawansowany
                  </h3>
                  <div style={{ marginTop: '-30px', marginBottom: '10px', marginLeft: '55px' }}>Szpital, Uzdrowisko</div>
                  <div className="price-point">
                    <strong className="price-accent"> 2590 zł netto / miesiąc</strong>
                  </div>
                  <div className="price-point">
                    Dostęp dla maksymalnie 10-ciu użytkowników z podmiotu, zakup
                    licencji na minimum 1 rok
                  </div>
                  <div className="divider"></div>
                  <div className="addl-feature">
                    <strong>Wsparcie konsultantów COplus</strong>
                  </div>
                  <div className="addl-feature">
                    <strong>Dostęp do bazy wiedzy</strong>
                  </div>
                  <div className="addl-feature">
                    <strong>Moduł kosztów i rentowności OPK</strong>
                  </div>
                  <div className="addl-feature">
                    <strong>Moduł wyceny procedur</strong>
                  </div>
                  <div className="addl-feature">
                    <strong>Moduł raportowy</strong>
                  </div>
                  <div className="addl-feature">
                    <strong>Moduł wyceny pacjenta</strong>
                  </div>
                  <a
                    href='/register/zaawansowany'
                    className="button in-pricing"
                  >
                    Zamów
                  </a>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div id="kontakt" className="section clients" style={{ backgroundColor: 'white' }}>
          <div className="w-container">
            <div className="div-block-2">
              <div style={{ fontSize: '40px', fontWeight: 'normal', marginTop: '40px', marginBottom: '60px', color: '#FE8E3C' }}>
                Dołącz do kilkuset zadowolonych użytkowników!
              </div>
              <div style={{ fontSize: '32px', fontWeight: 'normal', marginTop: '40px', marginBottom: '60px', color: '#0B6471', textAlign: 'right' }}>
                Zaplanuj spotkanie on-line
              </div>
              <div style={{ display: 'flex', justifyContent: "space-between", flexDirection: 'row' }}>
                <div>
                  <div style={{ fontSize: '24px', fontWeight: 'normal', marginTop: '10px', marginBottom: '0px', color: '#0B6471' }}>
                    Napisz lub zadzwoń do nas:
                  </div>
                  <div style={{ fontSize: '20px', fontWeight: 'normal', marginTop: '10px', marginBottom: '0px' }}>
                    mail: <a href="mailto:mampytanie@coplus.pl?subject=Proszę o kontakt w sprawie aplikacji CO+">
                      <span className="text-span">mampytanie@coplus.pl</span>
                    </a>
                  </div>
                  <div style={{ fontSize: '20px', fontWeight: 'normal', marginTop: '10px', marginBottom: '40px' }}>
                    telefon: 514 132 420
                  </div>
                </div>
                <div>
                  <img style={{ marginTop: '-40px' }} src='/logo192.svg'>
                  </img>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section footer copyright">
          <div className="w-container">
            <div>Copyright 2023 COplus Sp. z o.o. ul. Dębowa 60, 62-002 Suchy Las, NIP 972 131 27 63. All Rights Reserved.</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withAuth0(Home);